import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoadingScreen from "../../common/components/loadingScreen";
import InfiniteScroll from "react-infinite-scroll-component";
import { homeConstants } from "../../constants";
import { ContentService } from "../../services";
import Header from "../header/header";

function HomePage({ state, updateState }) {
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setHasMore(true);
  }, [state.latestRenders]);

  const fetchMoreData = async () => {
    try {
      const response = await new ContentService().getLatestRender({
        page: state.latestRenders.length / homeConstants.ITEMS_PER_PAGE + 1,
      });
      updateState({ latestRenders: [...state.latestRenders, ...response] });
      if (response.length === 0) {
        setHasMore(false);
      }
    } catch (error) {}
  };
  const getRightImages = (images) => {
    const imagesArray = [...images];
    return imagesArray.length > 2 ? imagesArray.splice(1) : imagesArray;
  };

  return (
    <>
      <Header />
      <div className="bg-blue-80 h-full pb-10 px-10 md:px-5 sm:px-5 xs:px-5">
        <div className="text-ft9 font-normal text-white mt-12 mb-8 bg-blue-80 font-SpaceGrotesk">
          Latest Renders
        </div>

        <InfiniteScroll
          dataLength={state?.latestRenders?.length || 0}
          next={fetchMoreData}
          hasMore={hasMore}
          // loader={
          //   <div className="bg-blue-80 ">
          //     <div className="relative flex justify-center text-white animate-bounce">
          //       loading...
          //     </div>
          //   </div>
          // }
        >
          <div className="grid grid-cols-2 xs:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-5 ">
            {state?.latestRenders && state?.latestRenders?.length > 0 ? (
              state?.latestRenders?.map((image, index) => (
                <Link
                  key={index}
                  to={`/result-render/${image._id}`}
                  className="relative grid xs:grid-cols-1 grid-cols-2 gap-3.5  border border-grey-40 rounded-5 p-4 bg-grey-40 "
                >
                  <div className="relative ">
                    <img
                      className=" flex-grow rounded-4.5 h-108 w-108 object-fill  "
                      key={index}
                      src={image.originalImage}
                      alt={image.alt}
                    />
                    <div className=" absolute flex top-5 left-5 space-x-1">
                      <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                        Orignal
                      </div>
                      <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                        V1
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {getRightImages(image?.interiorImage)?.map((data, idx) => (
                      <div className="relative w-full" key={idx}>
                        <img
                          className={`flex-grow h-108 w-108 rounded-4.5  object-fill `}
                          key={idx}
                          src={data}
                          alt={image.alt}
                        />
                        <div className=" absolute flex top-5 left-5 space-x-1">
                          <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                            {image?.style}
                          </div>
                          <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                            V1
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Link>
              ))
            ) : (
              <div className="bg-blue-80 h-screen flex justify-center items-center">
                <LoadingScreen />
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default HomePage;
