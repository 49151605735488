/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

export const httpConstants = {
  METHOD_TYPE: {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
  },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png",
  },
  DEVICE_TYPE: {
    WEB: "web",
  },
  API_END_POINT: {
    GET_INTERIOR: "/get-interior",
    GENERATE_INTERIOR: "/generate-interior",
    GET_CREATED_INTERIOR: "/get-interior-image",
    GET_STYLE_IMAGES: "/style-images",
    PUBLISH_IMAGES: "/publish-generate-interior",
    DELETE_CREATED_INTERIOR: "/delete-generate-interior"
  },
};
export const selectDesignImage = [
  { src: "/images/select-design.svg", title: "Modern" },
  { src: "/images/select-design.svg", title: "Minimal" },
  { src: "/images/select-design.svg", title: "Tropical" },
  { src: "/images/select-design.svg", title: "Wooden" },
  { src: "/images/select-design.svg", title: "Abstract" },
  { src: "/images/select-design.svg", title: "Modern" },
  { src: "/images/select-design.svg", title: "Modern" },
  { src: "/images/select-design.svg", title: "Modern" },
  { src: "/images/select-design.svg", title: "Modern" },
];
export const selectDesign = [
  { src: "/images/interial.svg", title: "Original" },
  { src: "/images/topical-v1.svg", title: "Tropical, V1" },
  { src: "/images/interial-v1.svg", title: "Modern, V2" },
];

export const selectMode = [
  "Virtual staging (locks walls, slower)",
  "Interior design(more creative, fast)",
  "Freestyle (no image needed, very fast)",
];
export const selectRoom = [
  "Living room",
  "Bedroom",
  "Bathroom",
  "Attic",
  "Kitchen",
  "Dining room",
  "Study room",
  "Home office",
  "Gaming room",
  "House exterior",
  "Outdoor pool area",
  "Outdoor patio",
  "Outdoor garden",
  "Meeting room",
  "Workshop",
  "Fitness gym",
  "Coffee shop",
  "Clothing store",
  "Walk in closet",
  "Toilet",
  "Restaurant",
  "Office",
  "Coworking space",
  "Hotel lobby",
  "Hotel room",
  "Hotel bathroom",
  "Exhibition space",
  "Onsen",
  "Mudroom",
  "Drop zone",
];
export const sliderData = [
  { src: "/images/interial.svg", title: "Modern" },
  { src: "/images/interial.svg", title: "Modern" },
  { src: "/images/interial.svg", title: "Modern" },
];
export const selectStyle = [
  "Modern",
  "Minimalist",
  "Contemporary",
  "Scandinavian",
  "Zen",
  "Midcentury Modern",
  "Tropical",
  "Biophillic",
  "Industrial",
  "Art deco",
  "Farmhouse",
  "Japanese design",
  "Rustic",
  "Bohemian",
  "Coastal",
  "Vintage",
  "Cottagecore",
  "French country",
  "Art nouveau",
  "Gaming room",
  "Cyberpunk",
  "Maximist",
  "Baroque",
  "Vaporwave",
  "Ski chalet",
  "Christmas",
  "Sketch",
  "Tribal",
  "Medieval",
  "Chinese new year",
  "Halloween",
  "Neoclassic",
];
export const selectInterialDesign = [
  { src: "/images/version-one.svg" },
  { src: "/images/version-two.svg" },
  { src: "/images/version-three.svg" },
  { src: "/images/version-four.svg" },
];

export const imageButton = [
  {
    icon: "/images/thumb-up.svg",
    label: "",
    className: "rounded-5-5xl cursor-pointer",
  },
  {
    icon: "/images/thumb-down.svg",
    label: "",
    className: "rounded-5-5xl cursor-pointer",
  },
  { icon: "/images/share-icon.svg", label: "Share" },
  { icon: "/images/download.svg", label: "Download" },
  { icon: "/images/upscale.svg", label: "Upscale" },
  { icon: "/images/delete.svg", label: "Delete" },
  { icon: "/images/eye-icon.svg", label: "Publish" },
];

export const homeConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
  },
  ITEMS_PER_PAGE: 10,
};
