import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ContentService } from "../../services";
import LoadingScreen from "../../common/components/loadingScreen";
import Utils from "../../utility";
import SocialMediaPopup from "../../common/components/socialSharePopup";
import Header from "../header/header";

function RenderResult() {
  const [resultRender, setResultRender] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const interiorData = await new ContentService().getCreatedInteriorById(
          id
        );
        setResultRender(interiorData);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [id]);

  const createdOnText =
    resultRender?.addedOn && Utils.epocToPrettyTime(resultRender?.addedOn);
  const isLessThan24Hours =
    Date.now() - resultRender?.addedOn < 24 * 60 * 60 * 1000;

  return (
    <div className="min-h-screen bg-theme-100">
      <Header />
      <div className="bg-blue-80 h-full pb-10 px-10 md:px-5 sm:px-5 xs:px-5">
        <div className="flex justify-between">

          <div className="font-themefont font-normal flex xs:flex-wrap-reverse sm:flex-wrap-reverse md:flex-wrap-reverse xs:gap-5 sm:gap-5 md:gap-5">
            <div className="flex items-end gap-2 xs:flex-col sm:flex-col md:flex-col xs:gap-1 sm:gap-1 md:gap-1">
              <Link
                  to={"/"}
                  className="text-white font-SpaceGrotesk text-ft9 flex gap-3 items-center  xs:text-ft7 sm:text-ft7 md:text-ft7 whitespace-nowrap"
              >
                <img src="/images/back-button.svg" alt="" />

              </Link>
              <span className="text-white font-SpaceGrotesk text-ft9 flex gap-3 items-center  xs:text-ft7 sm:text-ft7 md:text-ft7 whitespace-nowrap">Render Results</span>
              {createdOnText && (
                  <span className="font-themefont font-normal text-ft13 xs:mt-0 sm:mt-0 md:mt-0 text-grey-10 whitespace-nowrap xs:text-ft5 sm:text-ft5 md:text-ft5">
                Rendered {isLessThan24Hours ? "" : " on"} {createdOnText}
              </span>
              )}
            </div>
          </div>

          <div className="flex justify-end gap-x-2 mt-9">
            <img
                className="cursor-pointer h-10"
                src="/images/thumb-up.svg"
                alt=""
            />
            <img
                className="cursor-pointer h-10"
                src="/images/thumb-down.svg"
                alt=""
            />
            <div
                onClick={() => {
                  setShowPopUp(!showPopUp);
                }}
                className="rounded-3xl flex p-2 items-center justify-center h-10  cursor-pointer  border border-white text-white  xs:w-10 sm:w-10 md:w-10"
            >
              <img className="" src="/images/share-icon.svg" alt="" />
              <p className="text-ft2  md:w-auto xs:hidden sm:hidden md:hidden font-HelveticaBold pl-2">
                Share
              </p>
            </div>

            <a
                download
                href={
                  resultRender?.interiorImage ? resultRender?.interiorImage : "#"
                }
                className="rounded-3xl flex p-2 items-center justify-center h-10  cursor-pointer  border border-white text-white  xs:w-10 sm:w-10 md:w-10"
            >
              <img className="" src="/images/download.svg" alt="" />
              <p className="text-ft2  md:w-auto xs:hidden sm:hidden md:hidden font-HelveticaBold pl-2">
                Download
              </p>
            </a>

            <Link
                to={`/interior-preview/${id}`}
                className="rounded-3xl flex p-2 items-center justify-center h-10  cursor-pointer  border border-white text-white xs:w-10 sm:w-10 md:w-10"
            >
              <img className="" src="/images/eye-icon.svg" alt="" />
              <p className="text-ft2  md:w-auto xs:hidden sm:hidden md:hidden font-HelveticaBold pl-2">
                Preview
              </p>
            </Link>
          </div>

        </div>

        {showPopUp && <SocialMediaPopup setShowPopUp={setShowPopUp} />}
        {!loading ? (
          <>
            <Link
              to={`/interior-preview/${id}`}
              className="bg-grey-40 mt-4 p-3 rounded-5 grid xs:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 grid-cols-2 gap-3"
            >
              <div className="relative">
                <img
                  className=" md:h-833px md:max-h-833px w-full object-fill rounded-tl-5 rounded-bl-5 xs:rounded-5 sm:rounded-5 md:border-5"
                  src={resultRender?.originalImage}
                  alt=""
                />
                <div className=" absolute flex top-5 left-5 space-x-1">
                  <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                    Orignal
                  </div>
                  <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                    V1
                  </div>
                </div>
              </div>
              <div className="relative">
                <img
                  src={resultRender?.interiorImage}
                  className="md:h-833px md:max-h-833px w-full  rounded-tr-5 rounded-br-5  xs:rounded-5 sm:rounded-5 md:border-5"
                  alt=""
                />
                <div className=" absolute flex top-5 left-5 space-x-1">
                  <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                    {resultRender?.style}
                  </div>
                  <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                    V1
                  </div>
                </div>
              </div>
            </Link>
          </>
        ) : (
          <div className="bg-blue-80 h-screen flex justify-center items-center">
            <LoadingScreen />
          </div>
        )}
      </div>
    </div>
  );
}
export default RenderResult;
