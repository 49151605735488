import React, { useState, useReducer, useEffect, useRef } from "react";
import Header from "../header/header";
import InteriorResult from "./interiorResult";
import SelectCurrentInterior from "./selectCurrentInterior";
import SelectStyle from "./selectStyle";
import { ContentService } from "../../services";
import {
  homeConstants,
  selectMode,
  selectRoom,
  selectStyle,
} from "../../constants";
import reducer, { initialState } from "./reducer";
import SocialMediaPopup from "../../common/components/socialSharePopup";
import { history } from "../../managers/history";
import utility from "../../utility";

const { ACTION_TYPES } = homeConstants;
const { UDPATE_STATE } = ACTION_TYPES;

function RenderIdea() {
  const [isRenderData, setIsRenderData] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [imageId, setImageId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [styleSelect, setStyleSelect] = useState([]);
  const [renderImages, setRenderImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [timeOutError, setTimeOutError] = useState("");
  const [renderState, setRenderState] = useState({
    image: "",
    selectedRoom: selectRoom[0],
    selectedMode: selectMode[0],
    selectedStyle: selectStyle[0],
  });
  const [errors, setErrors] = useState({
    image: false,
    selectedRoom: false,
    selectedMode: false,
    selectedStyle: false,
  });
  const intervalRef = useRef(null);
  const mainRef = useRef();

  const handlePromptChange = (e) => {
    setRenderState((prevState) => ({
      ...prevState,
      addPrompt: e,
    }));
  };

  const handleRoomChange = (room) => {
    setRenderState((prevState) => ({
      ...prevState,
      selectedRoom: room,
    }));
  };

  const handleModeChange = (mode) => {
    setRenderState((prevState) => ({
      ...prevState,
      selectedMode: mode,
    }));
  };

  const handleStyleChange = (style) => {
    setRenderState((prevState) => ({
      ...prevState,
      selectedStyle: style,
    }));
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const updateState = (payload) => dispatch({ type: UDPATE_STATE, payload });

  async function generateInterior() {
    const { image } = state;

    let isErrored = false;
    const errorObject = {
      image: false,
      selectedRoom: false,
      selectedMode: false,
      selectedStyle: false,
    };
    if (!image.name && !renderState.selectedMode.includes("Freestyle")) {
      errorObject.image = true;
      isErrored = true;
    }
    if (!renderState.selectedStyle) {
      errorObject.selectedStyle = true;
      isErrored = true;
    }
    if (!renderState.selectedMode) {
      errorObject.selectedMode = true;
      isErrored = true;
    }
    if (!renderState.selectedRoom) {
      errorObject.selectedRoom = true;
      isErrored = true;
    }
    setErrors(errorObject);
    if (isErrored) return;

    setLoading(true);
    setIsRenderData(true);
    try {
      let interiorData = new FormData();
      if (!renderState.selectedMode.includes("Freestyle")) {
        interiorData.append("originalImage", image);
      }
      interiorData.append("userId", utility.generateGUID());
      interiorData.append(
        "prompt",
        // `generate a new interior ${renderState.selectedStyle} design ${renderState.selectedRoom} as based on input design``Generate new {choose style from user end} interior designs inspired by the input design, symmetrical room size, with exact room space with same angle, that feature different styles of furniture than the input design with realistic images, interior design, steampunk, atompunk, cyberpunk, designboom, archdaily, hyper realistic, cinematic, volumetric lighting, highly detailed, ambient occlusion, 16k, UHD, art room, attractive and creative sense with hyperrealistic paintings on the walls, minimal white interiors design, Corona render, daylight, radiates an elegant and neat vibe`
        `Generate new ${renderState.selectedStyle} interior designs ${renderState.selectedRoom} inspired by the input design, symmetrical room size, with exact room space with same angle, that feature different styles of furniture than the input design with realistic images, interior design, steampunk, atompunk, cyberpunk, designboom, archdaily, hyper realistic, cinematic, volumetric lighting, highly detailed, ambient occlusion, 16k, UHD, art room, attractive and creative sense with hyperrealistic paintings on the walls, minimal white interiors design, Corona render, daylight, radiates an elegant and neat vibe`
      );
      interiorData.append("a_prompt", `${renderState.selectedStyle} modern`);
      interiorData.append(
        "n_prompt",
        "blur, broken, bad anatomy, bw, black and white, ugly, tilling, poorly drawn hands feet, poorly drawn face, out of frame, extra limbs, disfigured, deformed, body out of frame, blurry, bad anatomy, blurred, watermark, grainy signature, cut off, draft"
      );
      interiorData.append("room", renderState.selectedRoom);
      interiorData.append("mode", renderState.selectedMode);
      interiorData.append("style", renderState.selectedStyle);
      const response = await new ContentService().generateInterior(
        interiorData
      );

      const imageResponse = await checkStoryStatusRecursive(response?._id);
      setRenderImages(imageResponse?.interiorImage);
      setLoading(false);
      mainRef.current.scrollIntoView();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      if (intervalRef.current !== null) clearInterval(intervalRef.current);
    };
  }, []);

  const checkStoryStatusRecursive = (id) => {
    const timeout = 180000;
    let elapsedTime = 0;

    return new Promise((resolve, reject) => {
      intervalRef.current = setInterval(async () => {
        try {
          const response = await new ContentService().getCreatedInteriorById(
            id
          );
          if (response?.status.toLowerCase() === "created") {
            setImageId(response?._id);
            clearInterval(intervalRef.current);
            resolve(response);
          } else if (response?.status.toLowerCase() === "errored") {
            clearInterval(intervalRef.current);
            reject("Error");
          } else {
            elapsedTime += 5000;
            if (elapsedTime >= timeout) {
              setTimeOutError(
                "Sorry, we were unable to generate your image. Please try again later or try with other interior-image."
              );
              clearInterval(intervalRef.current);
              reject("Timeout");
            }
          }
        } catch (error) {
          clearInterval(intervalRef.current);
          reject(error);
        }
      }, 5000);
    });
  };

  const deleteInterior = async () => {
    try{
      const response = await new ContentService().deleteInteriorById(
          imageId
      );
      if(response){
        history.push("/");
      }
    }
    catch(err){
      utility.apiFailureToast("Failed")
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await new ContentService().getStyleImage();
        setStyleSelect(response);
      } catch (error) {}
    })();
  }, []);

  const handleClick = async (isShare = false, url) => {
    setImageUrl(url);
    if (isShare) {
      setShowPopUp(true);
      return;
    }
    try {
      await new ContentService().publishImagesById(imageId);
      // utility.apiSuccessToast("Your image is successfully published");
    } catch (error) {
      console.log(error);
    }

    history.push("/");
  };

  return (
    <div className="min-h-screen bg-theme-100 font-themefont">
      <Header hideRenderButton />
      <div className=" bg-blue-80  flex pb-6 xs:flex-col md:flex-col sm:flex-col pl-5 pr-5">
        <div className="min-w-300px xs:w-full md:w-full sm:w-full">
          <SelectStyle
            selectedStyle={renderState.selectedStyle}
            updateStyle={(selectedStyle) => {
              setRenderState((pre) => ({ ...pre, selectedStyle }));
            }}
            styleSelect={styleSelect}
          />
        </div>
        <div className="w-30per xs:w-full md:w-full sm:w-full">
          <SelectCurrentInterior
            generateInterior={generateInterior}
            state={state}
            updateState={updateState}
            renderState={renderState}
            handleStyleChange={handleStyleChange}
            handleModeChange={handleModeChange}
            handlePromptChange={handlePromptChange}
            handleRoomChange={handleRoomChange}
            selectedStyle={renderState.selectedStyle}
            errors={errors}
            setErrors={setErrors}
            loading={loading}
          />
        </div>
        <div className="w-53per xs:w-full md:w-full sm:w-full">
          <InteriorResult
            loading={loading}
            timeOutError={timeOutError}
            isRenderData={isRenderData}
            renderImages={renderImages}
            renderState={renderState}
            handleClick={handleClick}
            deleteInterior={deleteInterior}
            mainRef={mainRef}
          />
        </div>
        {showPopUp && (
          <SocialMediaPopup
            showPopUp={showPopUp}
            setShowPopUp={setShowPopUp}
            url={imageUrl}
          />
        )}
      </div>
    </div>
  );
}

export default RenderIdea;
