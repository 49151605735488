import { httpConstants } from "../constants";

const createRequestOptions = (method, data, headers) => {
  const { GET } = httpConstants.METHOD_TYPE;
  const requestOptions = {
    method: method,
    headers: headers || { "Content-Type": "application/json" },
  };

  if (method !== GET) requestOptions.body = JSON.stringify(data);
  return requestOptions;
};

export const httpService = async (method, url, data, headers) => {
  const requestOptions = createRequestOptions(method, data, headers);

  try {
    const response = await fetch(url, requestOptions);
    const text = await response.text();
    const data = text && JSON.parse(text);
    if (!data.success) {
     
      const error = data.responseCode === 404 ? data : (data && data.message) || response.statusText;
      throw new Error(error);
    }
    return data;
  } catch (err) {
    throw err;
  }
};