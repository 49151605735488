import React from "react";
import { Router, Route } from "react-router-dom";
import { Switch } from "react-router";
import { connect } from "react-redux";
import { history } from "./managers/history";
import RenderIdea from "./modules/renderYourIdea";
import Home from "./modules/home";
import RenderResult from "./modules/renderResult";
import Preview from "./modules/preview";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path={"/render"} component={RenderIdea} />
        <Route exact path={"/result-render/:id"} component={RenderResult} />
        <Route exact path={"/interior-preview/:id"} component={Preview} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Routes);
