import React, { useState } from "react";
import { imageButton } from "../../constants";

import { BiLoaderAlt } from "react-icons/bi";

function InteriorResult({
  isRenderData,
  renderImages,
  loading,
  renderState,
  timeOutError,
  handleClick,
  deleteInterior,
  mainRef,
}) {
  const [showOptions, setShowOptions] = useState(false);
  // const isRenderData = true;
  // const renderImages = [
  //   "https://ai-tool-dev.s3.amazonaws.com//interior-ai/images/1681390696104.jpeg",
  // ];
  return (
    <div className="px-5">
      <div
        className="text-white text-ft7 pt-4 pb-4 flex font-themefont font-bold "
        ref={mainRef}
      >
        Your future interior
      </div>
      {!loading ? (
        <>
          {isRenderData ? (
            <div className="flex h-full w-full   " ref={mainRef}>
              {renderImages.map((item, index) => (
                <div
                  key={index}
                  className="w-full h-full  relative"
                  onPointerEnter={() => setShowOptions(true)}
                  onPointerLeave={() => setShowOptions(false)}
                >
                  <div className="absolute flex top-6 left-6 gap-2">
                    <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                      {renderState.selectedStyle}
                    </div>
                    <div className=" px-2.5 py-0.5 border filter bg-grey-60 bg-opacity-40 rounded-7   border-none text-white font-themefont  font-medium">
                      V1
                    </div>
                  </div>
                  <img
                    src={item}
                    className="rounded-xl  md:h-90per w-full "
                    alt=""
                  />

                  {showOptions && (
                    <div
                      style={{ backdropFilter: "blur(10px)" }}
                      className="w-full absolute rounded-b-xl p-5 min-h-90px  bg-opacity-80  bg-blue-80 flex-wrap flex  items-center justify-center gap-3.5 text-white font-themefont text-ft5 bottom-0"
                    >
                      {imageButton.map((button, index) => (
                        <div
                          key={index}
                          className={
                            button.className
                              ? button.className
                              : `rounded-3xl flex items-center justify-center px-3  h-10.5 cursor-pointer border`
                          }
                          onClick={() => {
                            if (
                              button.label === "Publish" ||
                              button.label === "Share"
                            ){
                                handleClick(button.label !== "Publish", item);
                            }
                            else if(button.label === "Delete"){
                                deleteInterior()
                            }
                          }}
                        >
                          {button.label === "Download" ? (
                            <a
                              download
                              href={item}
                              className="flex items-center justify-center gap-2"
                            >
                              <img className="" src={button.icon} alt="" />
                              {button.label && (
                                <p className="text-ft1 md:text-ft2 font-themefont">
                                  {button.label}
                                </p>
                              )}
                            </a>
                          ) : (
                            <button className="flex items-center justify-center gap-2">
                              <img className="" src={button.icon} alt="" />
                              {button.label && (
                                <p className="text-ft1 md:text-ft2 font-themefont">
                                  {button.label}
                                </p>
                              )}
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="flex justify-center items-center h-screen text-grey-10">
                {timeOutError
                  ? timeOutError
                  : `Rendered Results will be shown here`}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="flex   items-center h-screen justify-center ">
          <div className="flex justify-center items-center  text-grey-10">
            {timeOutError ? (
              <div className="flex justify-center items-center  text-grey-10">
                {timeOutError}
              </div>
            ) : (
              <div className="flex justify-center flex-col items-center  text-grey-10">
                <BiLoaderAlt className="w-12 h-12 animate-spin text-grey-10" />
                <div className="flex justify-center items-center  text-grey-10">
                  Your Interior is being ready please wait...
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default InteriorResult;
