import React from "react";
import DropDown from "../../common/components/dropdown";
import DragAndDropComponent from "./dragAndDropComponent";
import { selectMode, selectRoom, selectStyle } from "../../constants";

function SelectCurrentInterior({
  generateInterior,
  updateState,
  state,
  handleRoomChange,
  handleModeChange,
  handleStyleChange,
  renderState,
  selectedStyle,
  errors,
  loading,
  setErrors,
}) {
  return (
    <>
      <div className="text-white pl-5 text-ft7 pt-4 pb-4 flex font-bold  ">
        Your current interior
      </div>
      <div className="xs:h-full md:h-full sm:h-full h-screen w-full overflow-y-auto overflow-x-hidden px-5 interior">
        <div className="rounded-15">
          <DragAndDropComponent
            setErrors={setErrors}
            updateState={updateState}
            preview={state.inputImage.preview}
            renderState={renderState}
          />
          {errors.image && (
            <span className="text-red-50">Please select a image</span>
          )}
        </div>

        <h1 className="2xl:w-90per text-grey-0 text-ft3 font-themefont font-normal tracking-tighter mt-1">
          Select a photo of your current room. For best results make sure it
          shows the entire room in a
          <span className="underline">
            90° straight angle facing a wall or window horizontally (click for
            example).
          </span>
          <span className="">&nbsp;</span>
          Not from a corner or angled, and not a wide angle photo as it's
          trained on regular photos. The AI isn't great at angled pics (yet)!
          Uploads + renders are shown on site but auto deleted after 15 mins.
        </h1>
        {/* <div className="text-white text-ft7 mt-5 mb-5 font-HelveticaBold font-bold">
            Enter Prompt
          </div>
          <div className="bg-grey-50 border border-green-10 h-12.5 w-499px  text-white font-bold py-2 px-4 rounded-9 inline-flex items-center">
            <input
              type="text"
              value={renderState.addPrompt}
              onChange={(e) => handlePromptChange(e.target.value)}
              placeholder="Room with comfort chairs and working desk"
              className="text-ft4 bg-transparent placeholder-grey-10 w-full text-white font-themefont font-normal focus:outline-none"
            />
          </div> */}

        <div className="text-white text-ft7 mt-9 mb-4 font-HelveticaBold font-bold">
          Room
        </div>
        <DropDown
          value={renderState.selectedRoom}
          onChange={handleRoomChange}
          options={selectRoom}
          placeholder={"Living Room"}
        />
        {errors.selectedRoom && (
          <span className="text-red-50">Please select a room</span>
        )}

        <div className="text-white text-ft7 pt-10 mb-4 font-HelveticaBold font-bold">
          Mode
        </div>
        <DropDown
          options={selectMode}
          value={renderState.selectedMode}
          onChange={handleModeChange}
          placeholder={"Virtual staging (locks walls, slower)"}
        />
        {!renderState.selectedMode.includes("Freestyle") &&
          errors.selectedMode && (
            <span className="text-red-50">Please select a mode</span>
          )}

        <div className="mt-2.5">
          <h1 className="text-grey-0 text-ft3 font-themefont font-normal tracking-tighter ">
            You get widely different results with each mode. Virtual Staging
            mode will auto detect the construction (like walls, ceiling, beams)
            and tries to avoid changing it, while Interior Design mode doesn't
            but gives you more creative ideas. A good idea is to use Interior
            Design mode and then Mix w/ Original to get the original auto masked
            background back.
          </h1>

          <>
            <div
              className={`text-white text-ft7 pt-10 mb-4 font-HelveticaBold font-bold`}
            >
              Style
            </div>
            <DropDown
              options={selectStyle}
              value={renderState.selectedStyle}
              onChange={handleStyleChange}
              placeholder={selectedStyle}
            />
            {errors.selectedStyle && (
              <span className="text-red-50">Please select a style</span>
            )}
            <div className="text-white text-ft7 mt-5 mb-5 font-HelveticaBold font-bold">
              Create Version
            </div>
            <div className="bg-grey-50  h-12.5 max-w-499px w-full  text-white font-bold py-2 px-4 rounded-9 inline-flex items-center">
              <input
                type="text"
                placeholder="1"
                disabled
                className="text-ft4 bg-transparent placeholder-grey-10 w-full text-white font-themefont font-semibold focus:outline-none"
              />
            </div>
          </>
        </div>

        <button
          className="bg-green-10 hover: bg-gradient max-w-499px w-full mt-10 rounded-xl flex justify-center py-3.5"
          onClick={() => {
            generateInterior();
          }}
        >
          {loading ? (
            <span className="text-center text-white font-themefont text-ft6">
              Please wait...
            </span>
          ) : (
            <span className="text-center text-white font-themefont text-ft6">
              RENDER IDEA
            </span>
          )}
        </button>
      </div>
    </>
  );
}

export default SelectCurrentInterior;
