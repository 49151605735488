import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

function LoadingScreen() {
  return (
    <div className="relative">
      <BiLoaderAlt className="w-12 h-12 animate-spin text-grey-10" />
    </div>
  );
}

export default LoadingScreen;
