import React from "react";
import { Link } from "react-router-dom";

const Header = ({ hideRenderButton }) => {
  return (
    <div className="border-b border-grey-20 border-black-20 bg-blue-80 h-20 max-w-310 mx-auto flex justify-between  items-center px-10 md:px-5 sm:px-5 xs:px-5">
      <Link to="/">
        <img src="/images/redeqor-logo.svg" alt="App-logo" />
      </Link>
      {!hideRenderButton && (
        <Link
          to="/render"
          className="h-12 flex justify-center items-center px-12 md:px-5 sm:px-5 xs:px-5 font-themefont bg-gradient rounded-3xl text-white text-ft14 font-medium"
        >
          Render Your Idea
        </Link>
      )}
    </div>
  );
};

export default Header;
