import { homeConstants } from "../../constants";
const { ACTION_TYPES } = homeConstants;

const { UDPATE_STATE } = ACTION_TYPES;

export const initialState = {
  generatedInterior: [],
  inputImage: {},
  image: {},
};

export default function reducer(state, action) {
  switch (action.type) {
    case UDPATE_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
