import React, { useState } from "react";
import ClickOutside from "../../common/components/clickOutside";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown.svg";

const DropDown = ({ options, onChange, value, placeholder }) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <span
      onClick={() => setShowOptions((prev) => !prev)}
      className={`h-12.5 bg-grey-40 rounded-9  ${
        showOptions ? "border border-green-10" : ""
      } w-full block relative cursor-pointer`}
    >
      <div
        className={`font-themefont font-normal truncate flex justify-between items-center h-full pl-3 pr-3 ${
          value ? "text-white" : "text-grey-10"
        }`}
      >
        {value ? value : placeholder}
        <DropdownIcon
          className={`${showOptions ? "transform rotate-180" : ""}`}
        />
      </div>

      {showOptions && (
        <ClickOutside
          onClickOutside={setShowOptions}
          className={`absolute z-10 bg-white flex flex-col mt-1 left-0 top-12 right-0 mx-auto max-h-40 overflow-yy-auto overflow-x-hidden text-black-50 pl-3 pr-4 py-2 font-themefont font-normal rounded`}
        >
          {options.map(
            (item, idx) =>
              value !== item && (
                <button
                  className="block text-left"
                  onClick={() => onChange(item)}
                  key={idx}
                >
                  {item}
                </button>
              )
          )}
        </ClickOutside>
      )}
    </span>
  );
};

export default DropDown;
