import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../common/components/loadingScreen";
import { ContentService } from "../../services";
import SocialMediaPopup from "../../common/components/socialSharePopup";
import Header from "../header/header";

function PreviewPage() {
  const [imageURL, setImageURL] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [interiorPreview, setInteriorPreview] = useState("");

  const handleImageClick = (interiorPreview) => {
    setImageURL(interiorPreview);
  };

  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const interiorData = await new ContentService().getCreatedInteriorById(
          id
        );
        setInteriorPreview(interiorData);
        setImageURL(interiorData?.originalImage);
        setImageList(createImageList(interiorData));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [id]);

  const createImageList = (interiorData) => {
    let data = [];
    data.push(interiorData?.originalImage);
    interiorData.interiorImage.forEach((element) => {
      data.push(element);
    });
    return data;
  };

  const handleLeftArrowClick = () => {
    if (imageIndex === 0) {
      return;
    }
    const newIndex = (imageIndex - 1 + imageList.length) % imageList.length;
    setImageIndex(newIndex);
    setImageURL(imageList[newIndex]);
  };
  const handleRightArrowClick = () => {
    if (imageIndex === imageList.length - 1) {
      return;
    }
    const newIndex = (imageIndex + 1) % imageList.length;
    setImageIndex(newIndex);
    setImageURL(imageList[newIndex]);
  };

  return (
    <div className="min-h-screen bg-theme-100">
      <Header />
      <div className="bg-blue-80 min-h-screen py-5 pt-0 px-10 md:px-5 sm:px-5 xs:px-5">
        <div className="flex justify-between">
          <div className="flex mt-8">
            <Link
                to={`/result-render/${id}`}
                className="text-white text-ft9 font-SpaceGrotesk font-normal  flex items-center gap-4 xs:text-ft8 sm:text-ft8 md:text-ft8 whitespace-nowrap mr-2"
            >
              <img src="/images/back-button.svg" alt="" />
            </Link>
            <div className="text-white text-ft9 font-SpaceGrotesk font-normal flex items-center gap-4 xs:text-ft8 sm:text-ft8 md:text-ft8 whitespace-nowrap">Preview</div>
          </div>

          <div className="flex justify-end gap-x-2 mt-9 items-center">
            <img
                className="cursor-pointer h-10"
                src="/images/thumb-up.svg"
                alt=""
            />
            <img
                className="cursor-pointer h-10"
                src="/images/thumb-down.svg"
                alt=""
            />
            <div
                onClick={() => {
                  setShowPopUp(!showPopUp);
                }}
                className="rounded-3xl flex p-2 items-center justify-center h-10  cursor-pointer  border border-white text-white   xs:w-10 sm:w-10 md:w-10"
            >
              <img className="" src="/images/share-icon.svg" alt="" />
              <p className="text-ft2  md:w-auto xs:hidden sm:hidden md:hidden font-HelveticaBold pl-2">
                Share
              </p>
            </div>
            <a
                download
                href={
                  interiorPreview?.interiorImage
                      ? interiorPreview?.interiorImage
                      : "#"
                }
                className="rounded-3xl flex p-2 items-center justify-center h-10  cursor-pointer  border border-white text-white   xs:w-10 sm:w-10 md:w-10"
            >
              <img className="" src="/images/download.svg" alt="" />
              <p className="text-ft2  md:w-auto xs:hidden sm:hidden md:hidden font-HelveticaBold pl-2">
                Download
              </p>
            </a>
          </div>
        </div>


        {showPopUp && <SocialMediaPopup setShowPopUp={setShowPopUp} />}

        {!loading ? (
          interiorPreview ? (
            <>
              <div className="flex mt-15 xs:flex-wrap-reverse sm:flex-wrap-reverse md:flex-wrap-reverse justify-center xs:gap-5 sm:gap-5 md:gap-5">
                <div className="xs:pl-1 md:pl-1 xs:w-full md:w-full sm:w-full sm:pl-1 xs:pr-1 md:pr-1 sm:pr-1 pl-10 pr-10 h-full flex flex-col xs:flex-row md:flex-row sm:flex-row items-center gap-10 xs:gap-5 md:gap-5 sm:gap-5 overflow-auto justify-center">
                  <button
                    key={interiorPreview?.originalImage}
                    onClick={() =>
                      handleImageClick(interiorPreview?.originalImage)
                    }
                    className={`  ${
                      interiorPreview?.originalImage === imageURL
                        ? "border border-white rounded-xl"
                        : ""
                    }  cursor-pointer w-281px min-w-150px `}
                  >
                    <div className="relative">
                      <img
                        src={interiorPreview?.originalImage}
                        className="rounded-xl h-46 w-full"
                        alt=""
                      />
                      <div
                        style={{ backdropFilter: "blur(10px)" }}
                        className="bg-grey-60 bg-opacity-70 pt-7px pb-2 absolute bottom-0 w-full rounded-b-xl font-themefont text-ft3  filter text-white text-center"
                      >
                        <h2>Original</h2>
                      </div>
                    </div>
                  </button>

                  {interiorPreview?.interiorImage?.map((item) => (
                    <button
                      key={item}
                      onClick={() => handleImageClick(item)}
                      className={`${
                        item === imageURL
                          ? "border border-white rounded-xl"
                          : ""
                      }  min-w-150px w-281px cursor-pointer`}
                    >
                      <div className="relative">
                        <img
                          src={item}
                          className="rounded-xl h-46 w-full"
                          alt=""
                        />
                        <div
                          style={{ backdropFilter: "blur(10px)" }}
                          className="bg-grey-60 bg-opacity-70 pt-7px pb-2 absolute bottom-0 w-full rounded-b-xl font-themefont text-ft3  filter text-white text-center"
                        >
                          <h2>{interiorPreview?.style}</h2>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
                {interiorPreview && (
                  <div className="flex m-auto gap-x-15 md:gap-x-3 sm:gap-x-3 xs:gap-x-3  items-start justify-center ">
                    <button className="flex my-auto">
                      <img
                        onClick={handleLeftArrowClick}
                        className="cursor-pointer min-h-25px min-w-25px"
                        src="/images/left-arrow.svg"
                        alt=""
                      />
                    </button>
                    <div className="flex items-start">
                      <img
                        className=" rounded-xl object-contain"
                        src={imageURL}
                        alt=""
                      />
                    </div>
                    <button className="flex my-auto ">
                      <img
                        onClick={handleRightArrowClick}
                        className="cursor-pointer min-h-25px min-w-25px"
                        src="/images/right-arrow.svg"
                        alt=""
                      />
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="bg-blue-80  flex justify-center items-center text-theme-50">
              Failed to load
            </div>
          )
        ) : (
          <div className="bg-blue-80  flex justify-center items-center">
            <LoadingScreen />
          </div>
        )}
      </div>
    </div>
  );
}

export default PreviewPage;
