import React from "react";
import LoadingScreen from "../../common/components/loadingScreen";

function SelectStyle({ selectedStyle, updateStyle, styleSelect }) {
  return (
    <>
      <div className="text-white text-ft7  pt-4 pb-4 font-themefont font-bold pl-5">
        Select Style
      </div>
      <div className="xs:h-full md:h-full sm:h-full h-screen w-full overflow-y-auto overflow-x-hidden px-5 interior flex flex-col xs:flex md:grid md:grid-cols-4 sm:grid sm:grid-cols-3 md:gap-5 sm:gap-5 md:flex-wrap sm:flex-wrap">
        {styleSelect && styleSelect.length > 0 ? (
          styleSelect.map((image, index) => (
            <div
              key={index}
              onClick={() => {
                updateStyle(image.style);
              }}
              className={`${
                image.style === selectedStyle ? "border border-white" : ""
              } cursor-pointer mb-4 md:w-auto sm:w-auto w-236px rounded-xl xs:w-full xs:h-80 relative z-20 hover:opacity-90 duration-100 flex justify-center`}
            >
              <img
                src={image?.interiorImage[0]}
                className="xs:h-full h-157px w-236px md:w-full xs:w-full rounded-xl"
                alt=""
              />
              <div
                className="xs:w-full truncate bg-grey-60 bg-opacity-70  absolute bottom-0 w-full md:w-full rounded-b-xl font-themefont font-medium text-ft3 pl-3 pr-3 h-8 flex items-center  text-white justify-center"
                style={{ backdropFilter: "blur(10px)" }}
              >
                {image.style}
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center h-full">
            <LoadingScreen />
          </div>
        )}
      </div>
    </>
  );
}

export default SelectStyle;
