import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

function DragAndDropComponent({
  updateState,
  preview,
  setErrors,
  renderState,
}) {
  const [isImageValid, setIsImageValid] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop: async ([acceptedFiles]) => {
      setIsImageValid(false);
      if (acceptedFiles?.size < 10485760) {
        const image = new Image();
        const reader = new FileReader();
        reader.onload = () => {
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = 512;
            canvas.height = 512;
            ctx.drawImage(image, 0, 0, 512, 512);
            canvas.toBlob((blob) => {
              const file = new File([blob], "image.png", { type: "image/png" });
              updateState({
                inputImage: {
                  ...file,
                  preview: URL.createObjectURL(file),
                },
                image: file,
              });
              setErrors((pre) => ({ ...pre, image: false }));
            }, "image/png");
          };
          image.src = reader.result;
        };
        reader.readAsDataURL(acceptedFiles);
      } else {
        setIsImageValid(true);
      }
    },
  });

  useEffect(() => {
    const handlePaste = async (e) => {
      if (e.clipboardData?.items?.length > 0) {
        const item = e.clipboardData.items[0];
        if (item.type.includes("image")) {
          const renderPaste = item.getAsFile();
          if (renderPaste.size < 10485760) {
            updateState({
              inputImage: {
                ...renderPaste,
                preview: URL.createObjectURL(renderPaste),
              },
              image: renderPaste,
            });
            setIsImageValid(false);
          } else {
            setIsImageValid(true);
          }
        }
      }
    };
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [updateState]);

  return (
    <div
      {...getRootProps({
        className: `bg-grey-50 w-full h-64 ${
          !preview ? "flex" : ""
        } justify-center items-center rounded-15 cursor-pointer`,
      })}
    >
      {!preview ? (
        <>
          <input
            type="file"
            className="hidden"
            disabled={renderState.selectedMode.includes("Freestyle")}
            {...getInputProps()}
          />
          <h1 className="font-normal text-ft6 text-grey-10 p-1 text-center">
            Drop an image, tap to select or CTRL + V
          </h1>
          {isImageValid && (
            <div className="text-red-50">Please Upload less than 10mb file</div>
          )}
        </>
      ) : (
        <>
          <img
            className="rounded-lg h-full  w-full object-contain aspect-[3/2]"
            src={preview}
            alt=""
          />
          <h2 className="text-white font-themefont font-normal mt-neg50px text-center p-1">
            Drop an image, tap to select, or CTRL + V
          </h2>
        </>
      )}
    </div>
  );
}

export default DragAndDropComponent;
