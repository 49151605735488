import React, { useReducer, useEffect } from "react";
import Header from "../header/header";
import HomePage from "./homePage";
import { ContentService } from "../../services";
import { homeConstants } from "../../constants";
import reducer, { initialState } from "./reducer";

const { ACTION_TYPES } = homeConstants;

const { UDPATE_STATE } = ACTION_TYPES;

function Home() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const updateState = (payload) => dispatch({ type: UDPATE_STATE, payload });
  useEffect(() => {
    (async () => {
      try {
        const response = await new ContentService().getLatestRender();
        updateState({ latestRenders: response });
      } catch (error) {}
    })();
  }, []);

  return <HomePage state={state} />;
}

export default Home;
