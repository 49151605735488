import { httpService } from "../utility/httpService";
import { httpConstants } from "../constants";

const {
  METHOD_TYPE: { POST, GET ,PUT, DELETE },
  API_END_POINT: { GET_INTERIOR, GENERATE_INTERIOR, GET_CREATED_INTERIOR,GET_STYLE_IMAGES ,PUBLISH_IMAGES, DELETE_CREATED_INTERIOR},
} = httpConstants;

export default class ContentService {
  baseUrl = process.env.REACT_APP_CONTENT_SERVICE;
  localUrl = "https://redeqor.com:3000";

  getLatestRender() {
    return new Promise((resolve, reject) => {
      httpService(GET, this.localUrl + GET_INTERIOR)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  getStyleImage() {
    return new Promise((resolve, reject) => {
      httpService(GET, this.localUrl + GET_STYLE_IMAGES)
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  generateInterior(body) {
    return new Promise((resolve, reject) => {
      fetch(this.localUrl + GENERATE_INTERIOR, {
        method: POST,
        body,
      })
        .then(async (data) => {
          try {
            const res = await data.json();
            if (!res.success) {
              const error =
                res.responseCode === 500
                  ? res
                  : res?.message || data.statusText;
              return reject(error);
            }
            return resolve(res.responseData);
          } catch (err) {
            reject(err);
          }
        })
        .catch(reject);
    });
  }
  getCreatedInteriorById(id) {
    return new Promise((resolve, reject) => {
      httpService(GET, this.localUrl + GET_CREATED_INTERIOR + `?_id=${id}` )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
  publishImagesById(id) {
    return new Promise((resolve, reject) => {
      httpService(PUT, this.localUrl + PUBLISH_IMAGES + `?_id=${id}` )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }
    deleteInteriorById(id) {
        return new Promise((resolve, reject) => {
            httpService(DELETE, this.localUrl + DELETE_CREATED_INTERIOR + `?_id=${id}` )
                .then((res) => {
                    if (!res.success || res.responseCode !== 200) return reject(res);
                    return resolve(res?.responseData);
                })
                .catch(reject);
        });
    }
}
