import React, { useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  EmailShareButton,
} from "react-share";
import ClickOutside from "./clickOutside";

const SocialMediaPopup = ({ setShowPopUp, url }) => {
  const body = document.querySelector("body");
  const updatedUrl = (
    url ? url : window.origin + window.location.pathname
  ).trim();

  const handleClose = () => {
    setShowPopUp(false);
    body.style.overflow = "auto";
  };

  const shareTitle =
    "@Redeqor to create my dream interior design - it was easy and the results look amazing! #interiordesign #homedecor";

  useEffect(() => {
    body.style.overflow = "hidden";
  }, []);

  return (
    <>
      <div className="fixed inset-0 top-0 flex items-center justify-center bg-black-50 bg-opacity-50 z-50   font-themefont">
        <ClickOutside
          onClickOutside={handleClose}
          className="rounded-5 bg-green-20 p-12 xs:w-full sm:w-full"
        >
          <div className=" rounded-5 border-gray-10">
            <div className="flex justify-between pb-7">
              <div className="text-white font-themefont font-normal text-ft16 ">
                Share image
              </div>
              <button onClick={handleClose}>
                <img
                  className=" cursor-pointer"
                  src="/images/cross-icon.svg"
                  alt=""
                  height={21}
                  width={21}
                />
              </button>
            </div>
            <div className="flex gap-2">
              <EmailShareButton
                subject={shareTitle}
                body={updatedUrl}
                title={shareTitle}
                url={updatedUrl}
              >
                <img
                  className=" cursor-pointer"
                  src="/images/email-icon.svg"
                  alt=""
                />
              </EmailShareButton>
              <FacebookShareButton
                title={shareTitle}
                quote={shareTitle}
                url={updatedUrl}
                hashtags={["#interiordesign", "#homedecor"]}
              >
                <img
                  className=" cursor-pointer"
                  src="/images/facebook-icon.svg"
                  alt=""
                />
              </FacebookShareButton>
              <TwitterShareButton
                title={shareTitle}
                url={updatedUrl}
                hashtags={["#interiordesign", "#homedecor"]}
              >
                <img
                  className="  cursor-pointer"
                  src="/images/twitter-icon.svg"
                  alt=""
                />
              </TwitterShareButton>
              <WhatsappShareButton title={shareTitle} url={updatedUrl}>
                <img
                  className="cursor-pointer"
                  src="/images/whatsapp-icon.svg"
                  alt=""
                />
              </WhatsappShareButton>
              <InstapaperShareButton title={shareTitle} url={updatedUrl}>
                <img
                  className=" cursor-pointer"
                  src="/images/instagram-icon.svg"
                  alt=""
                />
              </InstapaperShareButton>
              <PinterestShareButton
                title={shareTitle}
                url={updatedUrl}
                media={updatedUrl}
              >
                <img
                  className=" cursor-pointer"
                  src="/images/pinterest-icon.svg"
                  alt=""
                />
              </PinterestShareButton>
            </div>
            <div className="pt-15 pb-6 w-auto text-white font-themefont font-normal text-ft16">
              Or copy link
            </div>
            <div className="flex gap-4 sm:flex-col xs:flex-col">
              <div className="xs:w-full sm:w-full w-80per border border-green-30 text-primary-60 text-ft17   flex items-center rounded-2.5 ">
                <span className="p-5 text-white truncate">
                  {`${updatedUrl}`}
                </span>
              </div>
              <CopyToClipboard text={updatedUrl}>
                <button className="sm:p-5 xs:p-5 xs:w-full sm:w-full w-20per bg-green-30 text-ft17 flex items-center font-themefont  justify-center  rounded-2.5 text-white">
                  Copy
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </ClickOutside>
      </div>
    </>
  );
};

export default SocialMediaPopup;
